<template>
    <div>
    <b-card no-body>
        <div class="m-2 p-1">
            <b-row class="form-boder-input">
                <b-col md="3">
                    <div class="demo-vertical-spacing ml-1">
                        <b-input-group class="input-group-merge mt-2">
                            <b-form-input placeholder="ค้นหา" v-model="searchQuery" />
                            <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" color="#58ADEB" />
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col md="3">
                    <div class="demo-vertical-spacing ml-1">
                        <b-form-group
                            label="วันที่"
                            label-for="date"
                            >
                            <b-form-datepicker
                                id="date"
                                v-model="searchDate"
                                type="date"
                            />
                        </b-form-group>
                    </div>
                </b-col>
                <b-col
                    md="3"
                >
                    
                    <div class="demo-vertical-spacing ml-1">
                        <b-form-group
                        label="เลือกลูกค้า(ผู้ป่วย)"
                        label-for="patient"
                        >
                        <v-select
                            :options="Patient_Data"
                            v-model="patient_id"
                            :get-option-label="(option) => option.first_name +' '+ option.last_name"
                            :reduce="val => val.id"
                            >
                            <template #option="{ first_name,last_name }">
                            {{ first_name }} {{ last_name }}
                            <br />
                            </template>
                        </v-select>
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
            ref="refListTable"
           
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
                <span 
                v-if="props.column.field === 'full_name'"
                >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                    :to="{ name: 'patients-detail', params:{id:props.row.patient_id}}"
                >
                {{  props.row.full_name }}
                </b-button>
                  
                  

                </span>
                <span
                v-else-if="props.column.field === 'action'"
                class="text-nowrap"
                >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle" 
                    :to="{ name: 'queue-detail', params:{id:props.row.id}}"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>

                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
        </div>
    </b-card>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useOpdList from './useOpdList'
import opdStoreModule from '../opdStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'

import queueStoreModule from '@/views/queue/queueStoreModule'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,
        VueGoodTable,
        vSelect

    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    setup() {

        const PATIENTS_STORE_MODULE_NAME = 'app-opd';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, opdStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const QUEUE_STORE_MODULE_NAME = 'app-queue';
        if (!store.hasModule(QUEUE_STORE_MODULE_NAME)) store.registerModule(QUEUE_STORE_MODULE_NAME, queueStoreModule)
        onUnmounted(() => {
            if (store.hasModule(QUEUE_STORE_MODULE_NAME)) store.unregisterModule(QUEUE_STORE_MODULE_NAME)
        });
        
        const {
            tableColumns,
            fetchData,
            deleteOpdCardData,
            deleteData,
            Patient_Data,
            patient_id,
            pageLength,
            Data,
            Total,
            searchQuery,
            searchDate,
            currentPage,

        } = useOpdList();
        return {
            tableColumns,
            fetchData,
            deleteOpdCardData,
            deleteData,
            pageLength,
            Data,
            Total,
            searchQuery,
            searchDate,
            currentPage,
            Patient_Data,
            patient_id,
        }
        
    },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(opdcard_id,opd_id,code){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete opdcard: '+code, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
            if(value === true){
            this.deleteOpdCardData(opdcard_id) //delete opd card
            this.deleteData(opd_id) //delete opd
          }
        })

        },
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>